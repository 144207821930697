.navbar{
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}
.navbar-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;
}
.navbar-logo img{
    width: 100%;
}
.navbar-logo-description{
    color: #000;
    font-size: 14.633px;
    font-style: normal;
    font-weight: 600;
    margin-top: 3px;
    line-height: 20.904px;
}
.navbar-items{
    display: flex;
    gap: 40px;
    height: 41px;
    align-items: center;
}
.navbar-item{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    transition: 0.1s;
    cursor: pointer;
    color: #000;
}
.navbar-item:hover{
    color: #4D832F;
}
.navbar-item.active{
    color: #4D832F;
}
.navbar-item-button{
    background-color: #4D832F;
    border-radius: 20px;
    padding: 10px 20px 11px 20px;
    color: #fff;
}
.navbar-item-button:hover{
    color: #ffffff;
    background-color: #579436;
}
.navbar-resp-button{
    width: 24px;
    height: 24px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.navbar-resp-button svg path{
    transition: 0.1s;
}
.navbar-resp-button:hover svg path{
    fill: #4D832F !important;
}
.navbar-resp-button svg{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-xmark{
    margin-left: 4px;
    margin-top: 1px;
}