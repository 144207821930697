.contact h1{
    text-align: center;
    margin: 10px 0 64px 0;
}
.contact > .container{
    padding-bottom: 122px;
    overflow-y: clip;
}
.contact > .container > .bg-house{
    top: -108px;
}
.contact-cards{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.contact-card{
    width: 400px;
    height: 192px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #4D832F;
    border-radius: 16px;
}
.contact-card-desc p{
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #fff;
}
.contact-card-icon{
    width: 48px;
    height: 48px;
    margin-top: 50px;
}
.card-location{
    transition: 0.1s;
}
.card-location:hover{
    background-color: #579436;
}
.card-location .contact-card-icon{
    margin-bottom: 2px;
}
.card-location-link{
    margin-top: 7px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
}
.card-mail .contact-card-icon{
    margin-top: 48px;
    margin-bottom: 16px;
}
.contact-form-inputs{
    width: 2fr;
}
.contact-form-message{
    width: 1fr;
}
.contact-form form{
    display: flex;
    gap: 40px;
}
.contact-form-inputs-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 16px;
}
.contact-form-inputs-row:last-child{
    margin-bottom: 0;
}
.contact-form-input{
    width: 400px;
    display: flex;
    flex-direction: column;
}
.contact-form-input p{
    margin-bottom: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.contact-form-input input{
    width: 100%;
    height: 64px;
    padding: 18px 16px;
    border-radius: 8px;
    outline: 1px solid #CCC;
    background: #FFF; 
    font-family: 'Inter' sans-serif !important;
    box-sizing: border-box;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border: none;
    transition: 0.1s;
}
.contact-form-input input:focus{
    outline: 1px solid #6d6d6d;
}
textarea{
    font-family: inherit;
}
.contact-form-input textarea{
    width: 100%;
    height: 302px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: none;
    outline: 1px solid #CCC;
    background: #FFF; 
    resize: none;
}
.contact-form-inputs-text{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.contact-form-inputs-text a{
    text-decoration-line: underline; 
    color: #000;
}
.contact-form-inputs-text a:hover{
    color: #000;
}
.contact-form-btn{
    width: 400px;
    height: 64px;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 8px;
    outline: 1px solid #CCC;
    background: #FFF;
    transition: 0.1s;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    cursor: pointer;
}
.contact-form-btn.active{
    background: #4D832F;
    outline: none;
    font-weight: 600;
    color: #fff;
}
.contact-form-btn:hover{
    background: #579436;
    color: #fff;
}
.contact-form-input-title{
    margin-left: 16px;
}
.contact-form-input-checkmark{
    display: inline-flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    outline: 1px solid #CCC;
    justify-content: center;
    align-items: center;
}
.contact-form-input-checkmark::after{
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
}
.contact-form-message{
    width: 400px;
    display: flex;
    flex-direction: column;
}
.contact-form-message button{
    width: 100%;
    margin-top: 16px;
    height: 64px;
    border-radius: 8px;
    justify-content: center;
}