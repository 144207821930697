.services-question{
    display: flex;
    flex-direction: row;
    padding: 0;
    overflow: hidden;
    border-radius: 8px; 
}
.faq-content{
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 100%;
}
.faq-bookmark{
    width: 38px;
    min-height: 100%;
    background-color: #4D832F;
}
.services h1{
    text-align: center;
    margin: 10px 0 60px;
}
.services > .container{
    overflow-y: clip;
    padding-bottom: 122px;
}
.services > .container > .bg-house{
    top: 193px;
}
.faq-question-desc ul{
    margin: 0;
    padding-left: 22px;
}