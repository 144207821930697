.tiles{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 128px;
}
.tile{
    background-color: #4D832F;
    width: 290px;
    height: 352px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tile-icon{
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background-color: #34591F;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tile-icon img{
    width: 80px;
}
.tile-title{
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    max-width: 169px;
    text-align: center;
}
.tile-btn{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background-color: transparent;
    outline: 1px solid #FFF !important;
    padding: 10px 20px; 
}
.tile-btn svg{
    margin-left: 13px;
}
.tile-btn:hover{
    background-color: transparent;
    outline: 1px solid #e4e4e4 !important;
    color: #e4e4e4;
}