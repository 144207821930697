.loader-container{
    width: 100%;
    height: calc(100vh - 225px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    transform-origin: center center;
    transform: scale(0.5);
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #4D832F;
    border-color: #4D832F transparent #4D832F transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
