.order > .container > h1{
    text-align: center;
}
.order-id{
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin: 0;
    padding: 20px;
    max-width: 1137px;
    margin-left: auto;
    margin-right: auto;
}
.order > .container > h2{
    text-align: center;
    font-size: 35px;
    max-width: 1137px;
    margin-left: auto;
    margin-right: auto;
}
.order-info h2{
    font-size: 35px;
}
.order-info p{
    font-size: 30px;
    font-weight: 700;
}
.order > .container{
    padding-bottom: 122px;
    overflow-y: clip;
}