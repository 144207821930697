.notfound-text{
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    height: calc(100vh - 505px);
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    justify-content: center;
}
.notfound > .container{
    overflow-y: clip;
}