.bg-plan{
    position: absolute;
    top: 196px;
    left: 50%;
    transform: translateX(-50%) rotate(-16.83deg);
    user-select: none;
    pointer-events: none;
    z-index: -1;
}
.details-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.details-summary{
    grid-row: span 1;
}
.details-area, .details-description{
    grid-row: span 2;
}
.details-description{
    min-height: 484px;
}
.details-header{
    display: flex;
    margin: 18px 0 57px;
    align-items: center;
}
.details-header h1{
    margin-left: 48px;
}
.details-header-back{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4D832F;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    transition: 0.1s;
}
.details-header-back:hover{
    background-color: #579436;
}
.details-slider-button{
    display: flex;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
}
.details-slider-button:first-child{
    margin-right: 4px;
}
.details-slider-button:last-child{
    margin-left: 3px;
}
.details-slider-button:hover{
    border: 1px solid #5a5a5a;
}
.notclickable{
    border: 1px solid #5a5a5a;
    cursor: default;
}
.details-thumbnail{
    width: 733px;
    height: 508px;
    margin-bottom: 13px;
    cursor: pointer;
    transition: 0.1s filter;
}
.details-thumbnail:hover{
    filter: brightness(0.8)
}
.details-thumbnail img{
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.details-slider{
    display: flex;
    height: 106px;
    align-items: center;
    gap: 0 6px;
}
.details-scroll-images{
    width: max-content;
    height: 106px;
    transition: 0.3s;
}
.details-scroll-images-overflow{
    max-width: 678px;
    overflow: hidden;
}
.details-scroll-image{
    width: 162px;
    height: 106px;
    margin-right: 10px;
    display: inline;
    float: left;
    cursor: pointer;
    transition: 0.1s filter;
}
.details-scroll-image:hover{
    filter: brightness(0.8);
}
.details-scroll-image img{
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.details-area{
    border: 1px solid #CCCCCC;
    background-color: #fff;
    margin-left: 40px;
    border-radius: 16px;
    height: max-content;
}
.details-area-header{
    padding: 32px;
    box-sizing: border-box;
}
.details-area-header h3{
    font-size: 18px;
    margin: 0;
}
.details-area-header p{
    font-size: 14px;
    margin: 8px 0 0 0;
    padding: 0;
}
.details-area-summary{
    width: 100%;
    padding: 0 32px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
    background-color: #CCC; 
}
.details-area-area-more{
    padding: 16px 64px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.details-area-area-more-row{
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}
.details-area-area-more-row span:last-child{
    font-weight: 600;
}
.details-area-area-more hr{
    width: 100%;
    margin: 0;
    border: none;
    border-bottom: 1px solid #CCC;
}
.details-area button{
    margin: 32px auto;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
}
.details-area .price{
    font-weight: 700;
    margin-left: 4px;
}
.details-facades{
    width: 730px;
    height: 386px;
    border: 1px solid #CCC;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.details-facades-buttons{
    display: flex;
    gap: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
}
.details-facades-button{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    background-color: #4D832F;
    transition: 0.1s;
    cursor: pointer;
    user-select: none;
}
.details-facades-button:hover{
    background-color: #579436;
}
.details-facades-image{
    width: 612px;
    height: 338px;
}
.details-facades-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}
.details-description-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
.details-description-header-option{
    width: 100%;
    text-align: center;
    padding: 16px 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    border-bottom: 1px solid #CCC;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.1s color;
    user-select: none;
}
.details-description-header-option:hover{
    color: #8a8a8a;
}
.details-description-header-option.active{
    border-bottom: 4px solid #4D832F;
}
.details-description-description p{
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 0;
    min-height: 386px;
}
.details-question{
    display: flex;
    flex-direction: column;
}
.details-buttons{
    width: 100%;
    padding-left: 40px;
    box-sizing: border-box;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.details-question{
    display: flex;
    flex-direction: column;
}
.details-question-button{
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    height: 51px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: 0.1s;
}
.details-question-button:hover{
    background: #f5f5f5;
}
.details-question-description{
    border-radius: 10px;
    border: 1px solid #777777;
    background: #FFF;
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
}
.details-button{
    border-radius: 10px;
    justify-content: center;
    background-color: #00529E;
    gap: 10px;
}
.details-button:hover{
    background-color: #1f69ad;
}
.details-button.light{
    background-color: #00856D;
}
.details-button.light:hover{
    background-color: #12aa8e;
}
.details-plan{
    grid-column: 1 / -1;
    min-height: 706px;
    border: 1px solid #CCC;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 16px;
    margin-top: 44px;
    margin-bottom: 122px;
}
.details-plan-image{
    min-width: 730px;
    width: 730px;
    height: 706px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 0 0 16px;
    overflow: clip;
    transition: 0.1s filter;
    cursor: pointer;
}
.details-plan-image:hover{
    filter: brightness(0.9);
}
.details-plan-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.details-plan-info{
    width: 100%;
    position: relative;
    min-height: 100%;
    height: 100%;
    border-left: 1px solid rgba(0,0,0,0.2);
}
.details-plan-info-header h3{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    margin: 0;
}
.details-plan-info-header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 0;
    border-bottom: 1px solid rgba(0,0,0,0.2)
}
.details-plan-info-rooms{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 18px 40px;
    box-sizing: border-box;
}
.details-plan-info-room{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.details-plan-info-room span{
    font-size: 19.1px;
    font-style: normal;
    font-weight: 400;
}
.details-plan-info-room span:last-child{
    font-size: 19.1px;
    font-style: normal;
    font-weight: 600;
}
.details-plan-info-rooms hr{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.details-plan-info-mirror{
    margin-left: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
    margin-bottom: 87px;
}
.details-plan-info-mirror:hover{
    color: #5e5e5e;
}
.details-plan-info-mirror-btn{
    display: inline-flex;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #CCC;
    justify-content: center;
    align-items: center;
}
.details-plan-info-mirror-title{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 5px;
}
.details-plan-info-mirror-btn.active::after{
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: #4D832F;
}
.details-plan-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 26px 40px 0 40px;
    position: absolute;
    box-sizing: border-box;
    bottom: 26px;
}
.details-plan-button{
    width: 102px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #4D832F;
    transition: 0.1s;
    cursor: pointer;
}
.details-plan-button:hover{
    background: #579436;
}
.openedimage{
    position: fixed;
    z-index: 2;
    top: 0px;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(1px);
}
.openedimage-close{
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
}
.openedimage-close svg path{
    transition: 0.1s;
}
.openedimage-close:hover svg path{
    fill: #dfdfdf;
}
.openedimage-img{
    width: 70%;
}
.openedimage-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
}
.details-buttons a{
    width: 100%;
}
.details-buttons a button{
    width: 100%;
}
.opened-image-arrows{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10%;
    box-sizing: border-box;
}