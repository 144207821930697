.about{
    width: 100%;
    height: 440px;
    display: flex;
    gap: 80px;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
}
.about-imgs{
    width: 578px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.about-img{
    width: 192px;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}
.about-img.wider{
    width: 366px;
}
.about-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.about-info{
    width: 622px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.about-description{
    color: #000;
    font-feature-settings: 'liga' off;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}