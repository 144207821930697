.slider{
    width: 100%;
    height: 605px;
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    margin-top: 44px;
}
.slider-overlay{
    position: absolute;
    background-color: #000;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 1;
    user-select: none;
}
.slider-arrows{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    box-sizing: border-box;
}
.slider-arrow{
    position: relative;
    cursor: pointer;
    z-index: 4;
}
.slider-arrow svg path{
    stroke: #fff;
    transition: 0.1s;
}
.slider-arrow:hover svg path{
    stroke: #dddddd;
}
.slides{
    display: flex;
    height: 100%;
    position: absolute;
    width: max-content;
    transition: 1s;
}
.slide{
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.slide-info{
    position: relative;
    z-index: 2;
    max-width: 660px;
    margin-left: 138px;
}
.slide-title{
    color: #fff;
}
.slide-description{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
}
.slide-btns{
    display: flex;
    gap: 16px;
}
.slide-btn{
    display: flex;
    align-items: center;
}
.slide-btn svg{
    margin-left: 8px;
}
.btn-outline{
    background-color: transparent;
    outline: 1px solid #FFF !important;
}
.btn-outline:hover{
    background-color: transparent;
    outline: 1px solid #e4e4e4 !important;
    color: #e4e4e4;
}
.slide-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: none;
}
.slide-bg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    user-select: none;
}
.slides-dots{
    position: absolute;
    bottom: 32px;
    z-index: 2;
    display: flex;
    gap: 5px;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
}
.slide-dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.2;
    transition: 0.1s;
}
.slide-dot.active{
    opacity: 1;
}