.faq{
    text-align: center;
}
.faq h1{
    margin-bottom: 64px;
}
.faq-question{
    width: 100%;
    border-radius: 20px;
    border: 1px solid #CCC;
    background: #FFF;
    text-align: left;
    padding: 32px;
    box-sizing: border-box;
    transition: 0.3s;
    margin-bottom: 32px;
}
.faq-question:last-child{
    margin-bottom: 0px;
}
.faq-question-title{
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; 
}
.faq-question-desc{
    margin-top: 16px;
    font-size: 20px;
}
.faq-question-desc p{
    margin: 0;
}
.faq-question-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq-question-button{
    width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;
}
.faq-question-button svg{
    margin-bottom: 2px;
}
.button-icon-notexpanded{
    margin: 2px 0 0 0;
}
.faq-question-button:hover{
    background-color: #c2c2c2;
}
.faq-question-desc{
    height: 0px;
    overflow: hidden;
    margin-top: 0px;
    transition: 0.3s;
    opacity: 0;
}
.faq-question-desc-expanded{
    margin-top: 16px;
    height: max-content;
    opacity: 1;
}