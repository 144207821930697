.catalog-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.catalog-wrapper{
    margin-top: 10px;
}
.catalog-header-sort{
    display: flex;
    align-items: center;
}
.catalog-header-sort-text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.catalog-header-sort-input{
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    padding: 16px;
    margin-left: 18px;
    width: 185px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.1s;
    position: relative;
}
.catalog-header-sort-input-container{
    position: relative;
}
.catalog-header-sort-input:hover{
    border: 1px solid #666666;
}
.catalog-header-sort-input:hover > span{
    color: #666666;
}
.catalog-header-sort-input:hover > span svg path{
    stroke: #666666;
}
.catalog-header-sort-input-list{
    position: absolute;
    top: 60px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    padding: 16px;
    width: 185px;
    left: 18px;
    box-sizing: border-box;
    z-index: 1;
    visibility: hidden;
}
.catalog-header-sort-input-list.visible{
    visibility: visible;
}
.catalog-header-sort-input-list ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.catalog-header-sort-input-list ul li{
    cursor: pointer;
    padding: 8px 0;
}
.catalog-header-sort-input-list ul li:hover{
    color: #666666;
}
.catalog-filter{
    margin-top: 8px;
    margin-right: 40px;
    user-select: none;
}
.catalog-filter:first-child{
    margin: 0px;
}
.catalog-filter-box{
    background-color: #4D832F;
    width: 290px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    transition: 0.1s;
    cursor: pointer;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    height: 52px;
}
.catalog-filter-box:hover{
    background-color: #579436;
}
.catalog-filter-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}
.catalog-filter-list ul{
    list-style-type: none;
    margin: 0 0 0 16px;
    padding: 0;
}
.catalog-filter-list ul li{
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.catalog-filter-list ul li:last-child{
    margin-bottom: 16px;
}
.checkmark{
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
.checked::after{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #4D832F;
    border-radius: 2px;
}
.catalog-filter-list-title{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.catalog-container{
    width: 100%;
    display: flex;
    margin-top: 47px;
    padding-bottom: 258px;
}
.catalog .container{
    overflow-y: clip;
}
.catalog-project{
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    width: 455px;
    height: 507px;
    border: 1px solid #CCCCCC;
}
.catalog-project-image{
    width: 423px;
    height: 239px;
    border-radius: 8px;
    overflow: clip;
}
.catalog-project-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.catalog-project-header{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.catalog-project-header-title{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.catalog-project-header-area{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.catalog-project-description{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 2px 0 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 20px;
    white-space: nowrap;
}
.catalog-project-price{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 8px 0 0 0;
    margin-bottom: 16px;
}
.catalog-project-button-outline{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    color: #000;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #000 !important;
}
.catalog-project-button-outline:hover{
    border: 1px solid #666666 !important;
    color: #666666;
    background-color: #fff;
}
.catalog-project button svg{
    margin-left: 8px;
}
.catalog-project button{
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
}
.catalog-project button svg{
    display: flex;
    align-items: center;
}
.catalog-projects{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.list-notexpanded{
    display: none !important;
}
.catalog-project-button-notbuyable{
    cursor: not-allowed;
}
.catalog-project-button-notbuyable:hover{
    background-color: #4D832F;
}
.catalog-project-prices{
    display: flex;
    gap: 20px;
}
.newprice{
    color: #ff002b;
}
.oldprice{
    text-decoration: line-through;
    font-weight: 500;
    opacity: 0.5;
}
.catalog-project-sale{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}