.gallery-container{
    margin-top: 10px;
    text-align: center;
}
.gallery-container h1{
    margin-bottom: 64px;
}
.marginedtop{
    margin-top: 80px;
}
.gallery-projects{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}
.gallery-project{
    width: 400px;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.gallery-img{
    width: 100%;
    height: 100%;
}
.gallery-img img{
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gallery-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(77, 131, 47, 0.9);
    opacity: 0;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
}
.gallery-overlay:hover{
    opacity: 1;
}
.gallery-overlay h2{
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    margin: 0;
}
.gallery-overlay p{
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
}
.gallery > .container{
    overflow-y: clip;
    padding-bottom: 122px;
}
.gallery-project-main{
    height: auto !important;
    width: auto !important;
    grid-row: span 2;
    grid-column: span 2;
}
.gallery-openedimage .openedimage-img{
    width: 48%;
}