.banner{
    background-color: #4D832F;
    width: 100%;
    padding: 64px 0 64px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 128px;
}
.banner h1{
    color: #fff;
    max-width: 820px;
    text-align: center;
}
.banner p{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    margin: 32px 0;
    max-width: 620px;
    text-align: center;
}
.btn-outline svg{
    margin-left: 8px;
}