@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins&display=swap');
body{
    margin: 0;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
.container{
    width: 1280px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
h1{
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 125% */
    letter-spacing: -1.2px; 
    margin: 0;
}
button{
    background-color: #4D832F;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 16px 24px;
    border: none !important;
    outline: none !important;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.1s;
    display: flex;
    align-items: center;
}
button:hover{
    background-color: #579436;
}
a {
    text-decoration: none;
}
.promo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: #c4b004; */
    color: #fff;
    font-weight: 500;
    gap: 20px;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.1s;
    cursor: pointer;
    font-size: 12px;
}
.promo-counter{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.promo-counter-big{
    font-weight: 800;
    font-size: 16px;
}
.promo-counter-small{
    margin-left: -2px;
}
.promo-counter-space{
    font-weight: 800;
    font-size: 24px;
}
.promo-counter span:first-child{
    margin-right: 6px;
}
.promo-btn{
    padding: 6px 20px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 10px;
    font-weight: 700;
}
.promo p{
    margin: 0;
}
.promo:hover{
    filter: brightness(0.9);
}
@media screen and (max-width: 1370px) {
    .container{
        width: 1024px;
    }
    .slide{
        width: 1024px !important;
    }
    .navbar-items{
        gap: 30px !important;
    }
    .navbar-logo{
        width: 180px !important;
    }
    .navbar-logo-description{
        font-size: 11px !important;
    }
    h1{
        font-size: 36px !important;
        line-height: unset !important;
    }
    h2{
        font-size: 20px !important;
        line-height: unset !important;
    }
    .about-description, .slide-description, .banner > p {
        font-size: 16px !important;
    }
    button{
        font-size: 14px !important;
    }
    button svg{
        display: none;
    }
    .tile{
        width: 240px !important;
    }
    .projects-slider-imgs img {
        width: 465px !important;
        height: 280px !important;
    }
    .projects{
        margin: 128px 0 100px 0 !important;
    }
    .projects h1{
        margin-bottom: 32px !important;
    }
    .about-imgs{
        gap: 3%;
    }
    .faq-question-desc{
        font-size: 16px !important;
    }
    .gallery-projects{
        grid-gap: 61px !important;
    }
    .gallery-project{
        width: 300px !important;
        height: 300px !important;
    }
    .catalog-project{
        width: 327px !important;
        height: auto !important;
    }
    .catalog-project-image{
        width: 100% !important;
        height: 180px !important;
    }
    .navbar-item{
        font-size: 12px !important;
    }
    .contact-form-input{
        width: 311px !important;
    }
    .contact-form form{
        gap: unset !important;
        justify-content: space-between;
    }
    .contact-form-input input{
        font-size: 16px !important;
    }
    .contact-form-btn {
        width: 311px !important;
        font-size: 14px !important;
    }
    .contact-form-input-checkmark{
        width: 24px !important;
        height: 24px !important;
    }
    .contact-form-input-checkmark::after{
        width: 12px !important;
        height: 12px !important;
    }
    .contact-form-inputs-row{
        gap: 45px !important;
    }
    .contact-form-message{
        width: 311px !important;
    }
    .contact-form-message .contact-form-input{
        width: 100% !important;
    }
    .contact-form-inputs-text {
        font-size: 14px !important;
        line-height: auto !important;
    }
    .contact-form-input textarea{
        height: 294px !important;
    }
    .contact-card{
        width: 311px !important;
    }
    .details-summary{
        width: 600px;
    }
    .details-thumbnail{
        width: 100% !important;
        height: 420px !important;
    }
    .details-description{
        width: 600px;
    }
    .details-slider{
        justify-content: center;
    }
    .details-scroll-images-overflow {
        max-width: 508px !important;
    }
    .details-facades{
        width: 100% !important;
    }
    .details-facades-image{
        width: 100% !important;
        height: auto !important;
    }
    .details-plan-image{
        min-width: 600px;
    }
    .details-plan-image{
        min-width: 650px !important;
    }
    .footer-section-title{
        font-size: 20px !important;
    }
    .footer-section a{
        font-size: 12px !important;
    }
    .footer-section p{
        font-size: 12px !important;
    }
    .cart-form-label{
        width: 330px !important;
    }
    .cart-form-label textarea{
        height: 244px !important;
    }
    .cart-project-header-info p{
        font-size: 20px !important;
    }
}
@media screen and (max-width: 1100px) {
    .container{
        width: 900px;
    }
    .slide{
        width: 900px !important;
    }
    h1{
        font-size: 30px !important;
        line-height: unset !important;
    }
    h2{
        font-size: 18px !important;
        line-height: unset !important;
    }
    .about-img:first-child{
        display: none;
    }
    .about-description, .slide-description, .banner > p {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .about{
        height: 330px !important;
    }
    .wider{
        width: 400px !important;
    }
    .tiles{
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }
    .tile{
        width: 440px !important;
        height: 300px !important;
    }
    .projects-slider-arrow{
        width: 64px !important;
        height: 64px !important;
    }
    .gallery-project{
        width: 280px !important;
        height: 280px !important;
    }
    .gallery-projects{
        grid-gap: 30px !important;
    }
    .gallery-container > h1{
        margin-bottom: 32px !important;
    }
    .gallery-container > h1.marginedtop{
        margin-top: 48px !important;
    }
    .catalog-container{
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
    }
    .catalog-projects{
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: column !important;
        width: 570px !important;
    }
    .catalog-project{
        width: 100% !important;
    }
    .details-summary{
        width: 500px !important;
    }
    .details-description{
        width: 500px !important;
    }
    .details-scroll-images-overflow {
        max-width: 340px !important;
    }
    .details-area-summary{
        font-size: 14px !important;
    }
    .details-plan-image{
        min-width: 500px !important;
    }
    .footer-section-title{
        font-size: 18px !important;
    }
    .footer-section a{
        font-size: 10px !important;
    }
    .footer-section p{
        font-size: 10px !important;
    }
    .footer-logo{
        margin-right: 20px;
        width: 120px !important;
    }
    .footer-logo img{
        width: 120px !important;
    }
    .footer-logo p{
        font-size: 12px !important;
    }
    .contact-cards{
        flex-direction: column;
        gap: 20px !important;
    }
    .contact-card{
        width: 100% !important;
    }
    .contact-form form{
        flex-direction: column !important;
    }
    .contact-form-inputs-row{
        flex-direction: column !important;
        gap: 10px !important;
    }
    .contact-form-input{
        width: 100% !important;
    }
    .contact-form-btn{
        width: 100% !important;
    }
    .contact-form-message{
        width: 100% !important;
    }
    .contact-form > h2{
        margin-top: 40px;
    }
    .cart-form-label{
        width: 290px !important;
    }
    .cart-form-label textarea{
        height: 300px !important;
        font-size: 12px !important;
    }
    .cart-form-input{
        font-size: 12px !important;
        line-height: unset !important;
    }
    .cart-form-input-title{
        font-size: 12px !important;
        line-height: unset !important;
    }
    .payment-2{
        display: flex;
        align-items: center;
    }
}
@media screen and (max-width: 960px) {
    .container{
        width: 728px;
    }
    .slide{
        width: 728px !important;
    }
    .wider{
        width: 330px !important;
    }
    .slide-info{
        max-width: 450px !important;
    }
    .tile{
        width: 350px !important;
    }
    .navbar{
        align-items: center !important;
        padding-top: 20px !important;
    }
    .navbar-logo{
        width: 180px !important;
    }
    .navbar-items{
        height: 0px !important;
        overflow: hidden !important;
        position: fixed !important;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        gap: 20px !important;
        justify-content: center;
    }
    .navbar-resp-button{
        display: flex !important;
    }
    .navbar-logo-description{
        font-size: 10px !important;
        margin-top: 2px !important;
        line-height: auto !important;
    }
    .navbar-item{
        font-size: 18px !important;
        font-weight: 500 !important;
    }
    .navbar-item-button{
        padding: 15px 40px !important;
    }
    .navbar-expanded{
        height: 100vh !important;
    }
    .navbar-items > .navbar-resp-button{
        position: absolute;
        top: 30px;
        width: 100%;
    }
    .navbar-items .nav-xmark{
        margin-left: auto !important;
        margin-right: 3px;
    }
    .gallery-project{
        width: 340px !important;
        height: 340px !important;
    }
    .gallery-projects{
        grid-template-columns: 1fr 1fr !important;
        grid-gap: 45px !important;
    }
    .catalog-projects{
        width: 398px !important
    }
    .about-description, .slide-description, .banner > p {
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .about{
        height: 200px !important;
    }
    .investor h1{
        margin: 10px 0 !important;
    }
    .investor-content p, .rules-container p {
        font-size: 16px;
    }
    .details-container{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px;
    }
    .details-summary{
        width: 100% !important;
    }
    .details-scroll-images-overflow {
        max-width: 508px !important;
    }
    .details-area{
        margin-left: 0px !important;
    }
    .details-description{
        width: 100% !important;
    }
    .details-description-header-option{
        font-size: 18px !important;
    }
    .details-description-description p{
        font-size: 18px !important;
    }
    .details-buttons{
        margin: 0 !important;
        padding: 0 !important;
    }
    .details-plan{
        flex-direction: column !important;
    }
    .details-plan-image{
        border-radius: 16px 16px 0 0 !important;
        width: 100% !important;
        height: 670px !important;
    }
    .details-button{
        padding: 18px 0;
    }
    .details-description-description p{
        min-height: 280px !important;
    }
    .footer-logo{
        margin-right: 20px !important;
    }
    .faq-question-title{
        font-size: 14px !important;
    }
    .cart-form-label{
        width: 230px !important;
    }
    .cart-form-label textarea{
        height: 230px !important;
    }
    .cart-form-container{
        flex-direction: column;
    }
    .cart-form-label{
        width: 100% !important;
    }
    .cart-project-image{
        min-width: 200px !important;
    }
    .cart-project-header-info p{
        font-size: 16px !important;
    }
    .cart-project-price{
        font-size: 20px !important;
    }
    .cart-project-area{
        font-size: 20px !important;
    }
}
@media screen and (max-width: 820px) {
    .container{
        width: 576px;
    }
    .slide{
        width: 576px !important;
    }
    .tile{
        width: 100% !important;
    }
    .wider{
        width: 100% !important;
    }
    .about-imgs{
        width: 30% !important;
    }
    .about-info{
        width: 70% !important;
    }
    .about{
        gap: 40px !important;
    }
    .banner{
        padding: 48px 12px !important;
        box-sizing: border-box;
    }
    .slider{
        height: 400px !important;
    }
    .slide-info{
        width: 60%;
    }
    .slide-description{
        width: 80%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: auto !important;
    }
    button{
        font-size: 12px !important;
        padding: 8px 16px;
    }
    .faq-question{
        padding: 16px !important;
    }
    .faq-question-desc-expanded{
        font-size: 14px !important;
    }
    .footer-content{
        flex-direction: column !important;
        height: max-content !important;
    }
    .footer-logo{
        margin: 40px 0 !important;
    }
    .footer-sections{
        flex-direction: column;
        padding: 0 0 40px 0;
    }
    .footer-sections{
        height: max-content !important;
        gap: 40px;
    }
    .projects-slider.centered{
        display: flex !important;
    }
    .projects-slider.notcentered{
        display: none !important;
    }
    .gallery-project{
        width: 280px !important;
        height: 280px !important;
    }
    .gallery-projects{
        grid-template-columns: 1fr 1fr !important;
        grid-gap: 14px !important;
    }
    .gallery-container > h1{
        margin-bottom: 16px !important;
    }
    .gallery-container > h1.marginedtop{
        margin-top: 32px !important;
    }
    .catalog-container{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
    }
    .catalog-projects{
        width: 100% !important;
    }
    .catalog-project-image{
        height: 280px !important;
    }
    .catalog-filter{
        width: 100%;
    }
    .catalog-filter-box{
        width: 100% !important;
    }
    .about{
        height: 260px !important;
    }
    .investor-content p, .rules-container p {
        font-size: 14px;
    }
    .details-description-header-option {
        font-size: 14px !important;
        line-height: auto !important;
    }
    .details-description-description p{
        font-size: 14px !important;
        line-height: 20px !important;
        min-height: 240px !important;
    }
    .details-description-description p{
        min-height: 200px !important;
    }
    .footer-logo p{
        font-size: 18px !important;
    }
    .footer-section-title{
        font-size: 22px !important;
    }
    .footer-section a, .footer-section p{
        font-size: 18px !important;
    }
    .footer-logo{
        width: 180px !important;
    }
    .footer-logo img{
        width: 180px !important;
    }
    .services-question{
        padding: 0px !important;
    }
    .faq-question-button{
        min-width: 24px !important;
        max-width: 24px !important;
        height: 24px !important;
    }
    .faq-question-button svg{
        width: 10px;
    }
    .faq-question-desc {
        font-size: 14px !important;
    }
    .promo{
        flex-direction: column;
        gap: 4px;
    }
    .promo-btn{
        margin-top: 4px;
    }
}
@media screen and (max-width: 600px) {
    .container{
        width: 450px;
    }
    .slide{
        width: 450px !important;
    }
    .slide-info{
        margin-left: 90px !important;
    }
    .about-imgs{
        display: none !important;
    }
    .about-info{
        width: 100% !important;
    }
    h1{
        font-size: 20px !important;
    }
    h2{
        font-size: 16px !important;
    }
    .slider-arrows{
        padding: 0 32px !important;
    }
    .slide-info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .slide-btns{
        flex-direction: column;
        gap: 8px !important;
    }
    .slide-btns button{
        width: 160px;
        justify-content: center;
    }
    .slide-description{
        margin: 6px 0 10px 0;
        width: auto;
        font-size: 12px !important;
        line-height: auto !important;
        text-align: center;
    }
    .projects-slider-arrow{
        margin: 0 !important;
    }
    .projects-slider-imgs img{
        height: 187px !important;
        width: 310px !important;
        margin: 0 10px !important;
    }
    .gallery-project{
        width: 100% !important;
        height: 450px !important;
    }
    .gallery-projects{
        display: flex !important;
        flex-wrap: wrap;
    }
    .catalog-header-sort-text{
        display: none;
    }
    .catalog-project-image{
        height: 240px !important;
    }
    .catalog-container{
        margin-top: 20px !important;
    }
    .catalog-header{
        margin-top: 20px !important;
    }
    .projects-slider{
        height: 200px !important;
    }
    .details-scroll-images-overflow {
        max-width: 334px !important;
    }
    .details-thumbnail {
        height: 280px !important;
    }
    .details-plan-image{
        min-width: 100% !important;
        height: 400px !important;
    }
    .details-scroll-images-overflow {
        max-width: 162px !important;
    }
    .details-plan-info-room span{
        font-size: 14px !important;
    }
    .details-plan-info-header h3{
        font-size: 16px !important;
    }
    .cart-project-image{
        display: none;
    }
    .cart-project{
        height: 238px;
    }
    .details-header-back{
        height: 48px !important;
        min-width: 48px !important;
        width: 48px !important;
    }
    .details-header-back svg{
        width: 10px !important;
        margin-left: -2px !important;
    }
}
@media screen and (max-width: 500px) {
    .container{
        width: 380px;
    }
    .slide{
        width: 380px !important;
    }
    .slide-info {
        margin-left: 75px !important;
    }
    .slider-arrow svg{
        width: 10px;
    }
    .projects-slider-arrow{
        width: 40px !important;
        height: 40px !important;
    }
    .projects-slider-arrow svg{
        height: 15px !important;
    }
    .gallery-project{
        width: 100% !important;
        height: 380px !important;
    }
    .gallery-projects{
        display: flex !important;
        flex-wrap: wrap;
    }
    .catalog-project-image{
        height: 200px !important;
    }
    .details-plan-image{
        min-width: 100% !important;
        height: 340px !important;
    }
    .details-description-header-option{
        font-size: 12px !important;
    }
    .details-description-description p{
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .details-plan{
        min-height: auto !important;
    }
    .details-plan-image{
        min-width: 100% !important;
        height: 300px !important;
    }
    .details-description-header-option{
        padding: 16px 10px !important;
    }
}
@media screen and (max-width: 400px) {
    .container{
        width: 320px;
    }
    .slide{
        width: 320px !important;
    }
    .slide-info {
        margin-left: 65px !important;
    }
    .gallery-project{
        width: 100% !important;
        height: 320px !important;
    }
    .gallery-projects{
        display: flex !important;
        flex-wrap: wrap;
    }
    .catalog-header-sort-input{
        font-size: 12px !important;
        width: 150px !important;
        padding: 10px !important;
        margin: 0 !important;
    }
    .catalog-header-sort-input-list{
        font-size: 12px !important;
        width: 150px !important;
        padding: 10px !important;
        top: 50px !important;
    }
}