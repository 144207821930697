.footer{
    border-top: 1px solid rgba(30, 40, 51, 0.07);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.footer-logo{
    margin-right: 110px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-logo img{
    width: 180px;
    margin-bottom: 6px;
}
.footer-logo p{
    color: #1E1E1E;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 0;
}
.footer-copyright{
    color: #1E2833;
    text-align: center;
    font-family: 'Inter' sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.205px;
    border-top: 1px solid rgba(30, 40, 51, 0.07);
    padding: 9px;
    opacity: 0.5;
}
.footer-copyright a{
    color: #1E2833;
    transition: 0.1s;
}
.footer-copyright a:hover{
    color: #73899e;
}
.footer-content{
    height: 340px;
    display: flex;
    align-items: center;
}
.footer-sections{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 168px;
}
.footer-section{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.footer-section-title{
    color: #4D832F;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 9px;
}
.footer-section a{
    color: #1E1E1E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: 0.1s;
    text-decoration: underline;
}
.footer-section a:hover{
    color: #505050;
}
.footer-section p{
    color: #1E1E1E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}
.footer-section-more{
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    color: #000 !important;
    margin-top: 9px;
}