.bg-house{
    position: absolute;
    top: 339px;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;
    pointer-events: none;
    z-index: -1;
}
.bg-house2{
    position: absolute;
    top: 2427px;
    left: -305px;
    user-select: none;
    pointer-events: none;
    z-index: -1;
}
.home{
    overflow-y: clip;
}