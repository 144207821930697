.investor > .container{
    padding-bottom: 122px;
    overflow-y: clip;
}
.investor > .container > .bg-house{
    top: -108px;
}
.investor h1{
    text-align: center;
    margin: 10px 0 64px 0;
}
.investor-content{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px
}
.investor-content p{
    margin: 0;
}