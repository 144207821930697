.cart-project{
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    display: flex;
    position: relative;
}
.cart-project-image{
    min-width: 424px;
    height: 238px;
    border-radius: 8px;
}
.cart-project-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.cart-project-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
    width: 100%;
}
.cart-project-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-project-header-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cart-project-header h2, .cart-project-header p{
    margin: 0;
}
.cart-project-header p{
    margin-top: 4px;
}
.cart-project-footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-project-price{
    font-size: 34px;
    font-weight: 700;
}
.cart-project-area{
    font-size: 26px;
    font-weight: 400;
}
.cart-project-header-info-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-project-header-info h2{
    font-size: 33px;
}
.cart-project-header-info p{
    font-size: 26px;
}
.cart-form-container{
    display: flex;
    justify-content: space-between;
}
.cart-form-label h3{
    margin: 0;
    margin-bottom: 3px;
    font-weight: 600;
    margin-top: 32px;
}
.cart-form-label{
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top: 8px;
}
.cart-form-label > input{
    border-radius: 8px;
    border: 1px solid #CCC;
    background: #FFF;
    font-family: 'Inter', sans-serif;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    height: 64px;
    width: 100%;
    padding: 18px 16px;
    box-sizing: border-box;
}
.cart-form-label input::placeholder{
    font-weight: 400;
}
.cart > .container{
    padding-bottom: 122px;
    overflow-y: clip;
}
.cart-form-input-check{
    padding: 18px 16px;
    border-radius: 8px;
    outline: 1px solid #CCC;
    background: #FFF;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 13px;
    transition: 0.1s;
    cursor: pointer;
}
.cart-form-input-checkmark{
    min-width: 32px;
    height: 32px;
    border: 1px solid #CCC;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-form-input-title{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    margin-top: 3px;
}
.cart-form-input-check:hover{
    background: #4D832F;
    outline: none; 
    color: #fff;
}
.cart-form-input-check.checked{
    background: #4D832F;
    outline: none; 
    color: #fff;
}
.cart-form-input-checkmark::after{
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
}
.cart-form-label textarea{
    width: 100%;
    height: 302px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: none;
    outline: 1px solid #CCC;
    background: #FFF; 
    resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.color-green{
    color: #4D832F;
}
.cart-form-label-policy a{
    text-decoration: underline;
    transition: 0.1s;
}
.cart-form-label-policy a:hover{
    color: #579436;
}
.cart-form-label-policy-checkmark{
    min-width: 18px;
    height: 18px;
    border-radius: 4.8px;
    border: 1px solid #CCC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cart-form-label-policy{
    font-size: 17px;
    display: flex;
    gap: 8px;
}
.cart-form-label-policy-container h3{
    visibility: hidden;
}
.cart-form-label-policy-checkmark.checked::after{
    width: 12px;
    height: 12px;
    border-radius: 2px;
}
.cart-title{
    margin: 47px 0 18px 0;
    text-align: center;
}
.cart-form-label-policy > p{
    margin: 0;
}